import { Icon, IconProps } from "@chakra-ui/react";

export const Road = (props: IconProps) => {
  return (
    <Icon
      width="474"
      height="474"
      viewBox="0 0 474 474"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1188:7137)">
        <path
          d="M460.926 302.072H314.573C307.669 302.072 302.073 307.669 302.073 314.572V460.926C302.073 467.829 307.669 473.426 314.573 473.426C321.476 473.426 327.073 467.829 327.073 460.926V327.072H460.926C467.829 327.072 473.426 321.476 473.426 314.572C473.426 307.669 467.829 302.072 460.926 302.072Z"
          fill="black"
        />
        <path
          d="M158.853 302.072H12.5C5.597 302.072 0 307.669 0 314.572C0 321.476 5.597 327.072 12.5 327.072H146.353V460.926C146.353 467.829 151.949 473.426 158.853 473.426C165.757 473.426 171.353 467.829 171.353 460.926V314.572C171.353 307.669 165.757 302.072 158.853 302.072Z"
          fill="black"
        />
        <path
          d="M158.853 0C151.949 0 146.353 5.597 146.353 12.5V146.353H12.5C5.597 146.353 0 151.949 0 158.853C0 165.757 5.597 171.353 12.5 171.353H158.853C165.757 171.353 171.353 165.757 171.353 158.853V12.5C171.353 5.597 165.757 0 158.853 0Z"
          fill="black"
        />
        <path
          d="M314.573 171.353H460.926C467.829 171.353 473.426 165.757 473.426 158.853C473.426 151.949 467.829 146.353 460.926 146.353H327.073V12.5C327.073 5.597 321.476 0 314.573 0C307.669 0 302.073 5.597 302.073 12.5V158.853C302.073 165.757 307.669 171.353 314.573 171.353Z"
          fill="black"
        />
        <path
          d="M236.713 365.381C229.81 365.381 224.213 370.978 224.213 377.881V460.927C224.213 467.83 229.81 473.427 236.713 473.427C243.615 473.427 249.213 467.83 249.213 460.927V377.881C249.213 370.978 243.615 365.381 236.713 365.381Z"
          fill="black"
        />
        <path
          d="M236.713 108.046C243.615 108.046 249.213 102.449 249.213 95.546V12.5C249.213 5.597 243.615 0 236.713 0C229.81 0 224.213 5.597 224.213 12.5V95.546C224.213 102.449 229.81 108.046 236.713 108.046Z"
          fill="black"
        />
        <path
          d="M12.5 249.213H95.546C102.449 249.213 108.046 243.615 108.046 236.713C108.046 229.81 102.449 224.213 95.546 224.213H12.5C5.597 224.213 0 229.81 0 236.713C0 243.615 5.597 249.213 12.5 249.213Z"
          fill="black"
        />
        <path
          d="M460.926 224.213H377.88C370.977 224.213 365.38 229.81 365.38 236.713C365.38 243.615 370.977 249.213 377.88 249.213H460.926C467.829 249.213 473.426 243.615 473.426 236.713C473.426 229.81 467.829 224.213 460.926 224.213Z"
          fill="black"
        />
        <path
          d="M278.234 224.212H249.213V195.189C249.213 188.286 243.615 182.689 236.713 182.689C229.81 182.689 224.213 188.286 224.213 195.189V224.212H195.19C188.287 224.212 182.69 229.809 182.69 236.712C182.69 243.614 188.287 249.212 195.19 249.212H224.213V278.235C224.213 285.137 229.81 290.735 236.713 290.735C243.615 290.735 249.213 285.137 249.213 278.235V249.212H278.234C285.137 249.212 290.735 243.614 290.735 236.712C290.735 229.809 285.139 224.212 278.234 224.212Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1188:7137">
          <rect width="473.426" height="473.426" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
