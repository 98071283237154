import { transAsText } from "@geoalert/ui-translations";

import { TProcessingMessage } from "types";

type Options = {
  shouldReplace?: boolean;
  sanitize?: boolean;
};

export function translateErrorsMessages(
  messages: TProcessingMessage[],
  options: Options = { sanitize: true }
) {
  return messages
    .map(({ code: id, parameters: values }) =>
      transAsText({
        id,
        values,
        ...options,
      })
    )
    .filter(Boolean);
}

const withOrder = (msg: string, index: number) => `${index + 1}. ${msg}`;

export const toOrderList = (list: string[]) =>
  list.length > 1 ? list.map(withOrder).join("\n") : list.join("\n");
