import { MutationFunction, useMutation } from "react-query";
import { useStore } from "effector-react/scope";

import { useToast, UseToastOptions } from "@chakra-ui/react";

import { mapboxModel } from "models/mapbox";

type TResultHandler<TData = unknown> = (data: TData) => Promise<unknown> | void;

type ToastOptions = {
  msgSuccess?: string;
  msgError?: string;
  position?: UseToastOptions["position"];
};

interface TEnchancedMutation<TData, TError, TVariables> {
  mutationFunction: MutationFunction<TData, TVariables>;
  toast?: ToastOptions;
  onSuccess?: TResultHandler<TData>;
  onError?: TResultHandler<TError>;
}

export function useEnchancedMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void
>({
  mutationFunction,
  toast,
  onError,
  onSuccess,
}: TEnchancedMutation<TData, TError, TVariables>) {
  const toastController = useToast();
  const isMapOpen = useStore(mapboxModel.$mapLoaded);

  const position = toast?.position || isMapOpen ? "top" : "bottom";

  return useMutation<TData, TError, TVariables>(mutationFunction, {
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);

      if (!toast?.msgSuccess) return;
      toastController({
        title: toast.msgSuccess,
        status: "success",
        duration: 3000,
        isClosable: true,
        position,
      });
    },
    onError: (data) => {
      if (onError) onError(data);

      if (!toast?.msgError) return;
      toastController({
        title: toast.msgError,
        status: "error",
        duration: 9000,
        isClosable: true,
        position,
      });
    },
  });
}
