import { Icon, IconProps } from "@chakra-ui/react";

export const SegmentAnything = (props: IconProps) => {
  return (
    <Icon
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.33317 13.3338L9.22558 8.68359L9.51708 10.4237C9.56383 10.702 10.4878 16.2177 10.4878 16.2177C10.5968 16.8678 11.1726 17.3908 11.9547 17.5499L16.6665 18.5087L11.9515 19.4681C11.1713 19.6269 10.5968 20.1496 10.4878 20.7999L9.22575 28.3338L7.96367 20.8002C7.85471 20.1499 7.28013 19.6272 6.49988 19.4684L1.78467 18.509L6.50013 17.5494C7.28013 17.3906 7.80763 16.484 7.9165 15.8338"
        stroke="#292929"
        strokeWidth={1}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7314 5.60459L18.376 2.24609L18.5865 3.50284C18.6202 3.70384 19.2876 7.68739 19.2876 7.68739C19.3663 8.15693 19.7821 8.53464 20.347 8.64955L23.7499 9.34205L20.3446 10.035C19.7811 10.1496 19.3662 10.5271 19.2875 10.9968L18.376 16.4379L17.4645 10.997C17.3858 10.5273 16.9709 10.1498 16.4074 10.0351L13.002 9.34226L16.4076 8.64918C16.9709 8.53447 17.3519 7.87972 17.4305 7.41018"
        stroke="#292929"
        strokeWidth={1}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4365 18.4447L22.9504 15.7666L23.1183 16.7688C23.1452 16.9291 23.6774 20.1056 23.6774 20.1056C23.7401 20.48 24.0717 20.7812 24.5222 20.8729L27.2357 21.4251L24.5203 21.9776C24.071 22.069 23.7401 22.3701 23.6774 22.7446L22.9505 27.0835L22.2236 22.7448C22.1609 22.3703 21.83 22.0692 21.3806 21.9777L18.665 21.4252L21.3807 20.8726C21.83 20.7811 22.1337 20.259 22.1965 19.8846"
        stroke="#292929"
        strokeWidth={1}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
