import React from "react";

import { Trans } from "@lingui/macro";

import {
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { ExtendedSharedProject, SharedProjectUser } from "types";

import UserRow from "./user-row";

type Props = {
  sharedProject: ExtendedSharedProject;
  isMaintainer: SharedProjectUser | undefined;
  currentUserEmail: string | undefined;
};
export const SharedUsersTable: React.FC<Props> = ({
  sharedProject,
  isMaintainer,
  currentUserEmail,
}) => {
  const isOwner = Boolean(
    sharedProject.owners.some((user) => user.email === currentUserEmail)
  );

  return (
    <TableContainer mt={8}>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr height="3.125rem">
            <Th>
              <Text textAlign="left" fontSize="sm">
                <Trans>EMAIL</Trans>
              </Text>
            </Th>

            <Th pl="0">
              <Text textAlign="center" fontSize="sm">
                <Trans>Team Member</Trans>
              </Text>
            </Th>

            <Th>
              <Text textAlign="left" fontSize="sm">
                <Trans>Role</Trans>
              </Text>
            </Th>
            <Th fontSize="sm" textAlign="center" />
          </Tr>
        </Thead>

        <Tbody>
          {sharedProject.owners &&
            sharedProject.owners.map((owner) => (
              <UserRow
                user={owner}
                isProjectOwner
                isOwner={isOwner}
                isMaintainer={isMaintainer}
                ownersCount={sharedProject?.owners.length}
                currentUserEmail={currentUserEmail}
              />
            ))}

          {sharedProject.users &&
            sharedProject.users.map((user) => (
              <UserRow
                key={user.userId}
                user={user}
                isMaintainer={isMaintainer}
                isOwner={isOwner}
              />
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
