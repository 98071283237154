import { Box, Flex, Text } from "@chakra-ui/react";

import { FaCoins } from "@react-icons/all-files/fa/FaCoins";

import { Processing, ProcessingStatus } from "types";

import { format } from "date-fns";

type Props = {
  status: ProcessingStatus;
  created: Processing["created"];
  cost: Processing["cost"];
};

export const RightAddon: React.VFC<Props> = ({ created, status, cost }) => {
  return (
    <Flex alignItems="center" alignSelf="flex-start">
      {status === ProcessingStatus.OK && (
        <Text color="gray.500" mr="20px" fontSize="12px">
          {format(new Date(created), "yy-MM-dd HH:mm")}
        </Text>
      )}
      <Flex>
        <Box as={FaCoins} w="14px" h="14px" color="gray.500" />
        <Text ml="8px" color="gray.500" fontSize="12px">
          {cost}
        </Text>
      </Flex>
    </Flex>
  );
};
