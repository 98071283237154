import { Icon, IconProps } from "@chakra-ui/react";

export const CityScape = (props: IconProps) => {
  return (
    <Icon
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M427.546 36.9477V2.63867H411.711V36.9477H327.258V205.856H269.196V166.268H253.361V205.856H195.299V110.845H105.567V71.2577H89.732V110.845H0V509.361H15.835V126.68H179.464V509.361H195.299V221.691H327.258V509.361H343.093V52.7837H496.165V509.361H512V36.9477H427.546Z"
        fill="black"
      />
      <path
        d="M31.6699 147.795V205.857H163.629V147.795H31.6699V147.795ZM89.7319 190.022H47.5049V163.63H89.7319V190.022ZM147.794 190.022H105.567V163.63H147.794V190.022Z"
        fill="black"
      />
      <path
        d="M31.6699 232.246V290.308H163.629V232.246H31.6699ZM89.7319 274.473H47.5049V248.081H89.7319V274.473ZM147.794 274.473H105.567V248.081H147.794V274.473Z"
        fill="black"
      />
      <path
        d="M31.6699 316.701V374.763H163.629V316.701H31.6699ZM89.7319 358.928H47.5049V332.536H89.7319V358.928ZM147.794 358.928H105.567V332.536H147.794V358.928Z"
        fill="black"
      />
      <path
        d="M31.6699 401.154V459.216H163.629V401.154H31.6699ZM89.7319 443.38H47.5049V416.989H89.7319V443.38ZM147.794 443.38H105.567V416.989H147.794V443.38Z"
        fill="black"
      />
      <path
        d="M358.928 73.8965V131.958H480.33V73.8965H358.928ZM464.495 116.123H374.763V89.7315H464.495V116.123Z"
        fill="black"
      />
      <path
        d="M358.928 158.352V216.414H480.33V158.352H358.928ZM464.495 200.578H374.763V174.186H464.495V200.578Z"
        fill="black"
      />
      <path
        d="M358.928 242.805V300.867H480.33V242.805H358.928V242.805ZM464.495 285.032H374.763V258.64H464.495V285.032Z"
        fill="black"
      />
      <path
        d="M358.928 327.258V385.32H480.33V327.258H358.928ZM464.495 369.485H374.763V343.093H464.495V369.485Z"
        fill="black"
      />
      <path
        d="M358.928 411.711V469.773H480.33V411.711H358.928ZM464.495 453.938H374.763V427.546H464.495V453.938Z"
        fill="black"
      />
      <path d="M314.061 242.805H208.494V258.64H314.061V242.805Z" fill="black" />
      <path
        d="M314.061 285.031H208.494V300.866H314.061V285.031Z"
        fill="black"
      />
      <path
        d="M314.061 327.258H208.494V343.093H314.061V327.258Z"
        fill="black"
      />
      <path
        d="M314.061 369.484H208.494V385.319H314.061V369.484Z"
        fill="black"
      />
      <path
        d="M314.061 411.711H208.494V427.546H314.061V411.711Z"
        fill="black"
      />
      <path
        d="M314.061 453.938H208.494V469.773H314.061V453.938Z"
        fill="black"
      />
    </Icon>
  );
};
