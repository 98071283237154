import { Icon, IconProps } from "@chakra-ui/react";

export const Forest = (props: IconProps) => {
  return (
    <Icon
      width="252"
      height="253"
      viewBox="0 0 252 253"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M247.669 252.035H4.32718C2.00531 252.035 0.125977 250.153 0.125977 247.834C0.125977 245.515 2.00531 243.633 4.32718 243.633H247.669C249.988 243.633 251.87 245.515 251.87 247.834C251.87 250.153 249.991 252.035 247.669 252.035Z"
        fill="black"
      />
      <path
        d="M198.294 252.035C195.975 252.035 194.093 250.152 194.093 247.833V128.152C194.093 125.831 195.975 123.951 198.294 123.951C200.613 123.951 202.495 125.831 202.495 128.152V247.833C202.495 250.152 200.613 252.035 198.294 252.035Z"
        fill="black"
      />
      <path
        d="M198.293 179.598C197.218 179.598 196.142 179.189 195.322 178.368C193.681 176.727 193.681 174.069 195.322 172.428L208.76 158.987C210.401 157.346 213.059 157.346 214.701 158.987C216.342 160.628 216.342 163.286 214.701 164.927L201.262 178.368C200.442 179.186 199.366 179.598 198.293 179.598Z"
        fill="black"
      />
      <path
        d="M198.294 164.2C197.219 164.2 196.143 163.791 195.322 162.971L181.881 149.527C180.24 147.886 180.24 145.228 181.881 143.586C183.523 141.945 186.181 141.945 187.822 143.586L201.263 157.027C202.904 158.669 202.904 161.327 201.263 162.968C200.442 163.789 199.367 164.2 198.294 164.2Z"
        fill="black"
      />
      <path
        d="M56.1143 252.035C53.7924 252.035 51.9131 250.153 51.9131 247.834V154.926C51.9131 152.607 53.7924 150.725 56.1143 150.725C58.4361 150.725 60.3155 152.607 60.3155 154.926V247.834C60.3155 250.153 58.4361 252.035 56.1143 252.035Z"
        fill="black"
      />
      <path
        d="M56.1137 193.933C55.0382 193.933 53.9627 193.524 53.1421 192.703C51.5008 191.062 51.5008 188.404 53.1421 186.763L63.0429 176.862C64.6814 175.221 67.3421 175.221 68.9834 176.862C70.6247 178.503 70.6247 181.161 68.9834 182.802L59.0826 192.703C58.2647 193.521 57.1892 193.933 56.1137 193.933Z"
        fill="black"
      />
      <path
        d="M56.1142 182.586C55.0387 182.586 53.9632 182.177 53.1425 181.357L43.2417 171.459C41.6004 169.817 41.6004 167.159 43.2417 165.518C44.8802 163.877 47.5409 163.877 49.1822 165.518L59.083 175.416C60.7243 177.057 60.7243 179.715 59.083 181.357C58.2652 182.177 57.1897 182.586 56.1142 182.586Z"
        fill="black"
      />
      <path
        d="M125.47 252.035C123.148 252.035 121.269 250.153 121.269 247.834V102.564C121.269 100.243 123.148 98.3633 125.47 98.3633C127.792 98.3633 129.671 100.243 129.671 102.564V247.834C129.671 250.153 127.792 252.035 125.47 252.035Z"
        fill="black"
      />
      <path
        d="M125.47 168.532C124.395 168.532 123.319 168.123 122.499 167.303C120.857 165.661 120.857 163.003 122.499 161.362L140.068 143.795C141.709 142.154 144.367 142.154 146.008 143.795C147.65 145.437 147.65 148.095 146.008 149.736L128.439 167.303C127.621 168.12 126.546 168.532 125.47 168.532Z"
        fill="black"
      />
      <path
        d="M125.47 148.4C124.395 148.4 123.319 147.991 122.499 147.171L104.929 129.604C103.288 127.963 103.288 125.302 104.929 123.661C106.57 122.019 109.228 122.019 110.87 123.661L128.439 141.23C130.078 142.871 130.078 145.529 128.439 147.171C127.621 147.991 126.546 148.4 125.47 148.4Z"
        fill="black"
      />
      <path
        d="M125.47 191.894C97.3891 191.894 74.543 169.051 74.543 140.967C74.543 84.9509 102.422 25.6888 122.218 1.50108C123.812 -0.448277 127.125 -0.448277 128.719 1.50108C148.515 25.6888 176.391 84.9481 176.391 140.967C176.394 169.051 153.551 191.894 125.47 191.894ZM125.47 11.0266C106.808 36.4046 82.9454 90.0344 82.9454 140.97C82.9454 164.418 102.022 183.494 125.47 183.494C148.918 183.494 167.992 164.418 167.992 140.97C167.992 90.0344 144.132 36.4018 125.47 11.0266Z"
        fill="black"
      />
      <path
        d="M198.294 206.035C179.083 206.035 161.236 195.658 151.714 178.954C150.562 176.938 151.265 174.372 153.282 173.224C155.296 172.076 157.864 172.773 159.012 174.792C167.042 188.88 182.094 197.633 198.294 197.633C223.207 197.633 243.471 177.366 243.471 152.453C243.471 138.564 237.208 125.644 226.282 117.006C224.663 115.723 224.215 113.452 225.226 111.651C227.817 107.044 229.184 101.82 229.184 96.5491C229.184 79.5147 215.328 65.6563 198.294 65.6563C184.525 65.6563 172.299 74.8961 168.56 88.1271C167.93 90.3622 165.622 91.6645 163.373 91.0288C161.141 90.3986 159.841 88.0767 160.474 85.8445C165.23 69.0117 180.783 57.2539 198.294 57.2539C219.958 57.2539 237.586 74.8821 237.586 96.5491C237.586 102.092 236.399 107.587 234.13 112.637C245.448 122.807 251.873 137.133 251.873 152.453C251.87 181.999 227.834 206.035 198.294 206.035Z"
        fill="black"
      />
      <path
        d="M56.114 218.297C25.2436 218.297 0.125977 193.183 0.125977 162.312C0.125977 131.442 25.2436 106.324 56.114 106.324C65.281 106.324 74.378 108.59 82.4163 112.875C84.4637 113.968 85.2395 116.511 84.1472 118.558C83.0549 120.605 80.5117 121.381 78.4644 120.289C71.636 116.651 63.9086 114.727 56.1168 114.727C29.8761 114.727 8.53118 136.074 8.53118 162.312C8.53118 188.55 29.8789 209.895 56.1168 209.895C75.3191 209.895 92.5412 198.459 99.9941 180.764C100.893 178.624 103.363 177.621 105.495 178.52C107.635 179.422 108.637 181.884 107.736 184.024C98.969 204.842 78.7052 218.297 56.114 218.297Z"
        fill="black"
      />
    </Icon>
  );
};
