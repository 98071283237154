import React from "react";

import { t } from "@lingui/macro";

import {
  Avatar,
  Box,
  Flex,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";

export const ProjectAuthor: React.FC<{
  author: string;
  isProjectPage?: boolean;
}> = ({ author, isProjectPage }) => {
  const { onCopy, hasCopied } = useClipboard(author);

  return (
    <Box>
      <Tooltip
        aria-label="copy"
        label={hasCopied ? t`Copied!` : t`Click to copy email`}
        bg={hasCopied ? "green.500" : "gray.100"}
        color={hasCopied ? "white" : "black"}
      >
        <Box
          width={"fit-content"}
          onClick={(e) => {
            e.preventDefault();
            onCopy();
          }}
          cursor={"pointer"}
        >
          {isProjectPage ? (
            <Flex>
              <Avatar size={"xs"} mr={"6px"} ml={"10px"} />
              <Text color="gray.500">
                {t`Owner`} : {author}
              </Text>
            </Flex>
          ) : (
            <Text fontSize="xs" fontWeight={600} color="gray.500">
              {t`Author`} : {author}
            </Text>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
};
