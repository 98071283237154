import React, { VFC } from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";

import { ModalBodyContent } from "./modal-body-content";

type Props = Omit<ModalProps, "children"> & { projectId: string };

export const ModalProjectShare: VFC<Props> = ({ projectId, ...modalProps }) => {
  return (
    <Modal isCentered size="2xl" {...modalProps}>
      <ModalOverlay />
      <ModalContent p={4} borderRadius="ui-base">
        <ModalCloseButton
          data-cy="project-share-form__close-button"
          position="absolute"
          top={3}
          right={4}
        />
        <ModalBody p={2}>
          <ModalBodyContent projectId={projectId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
