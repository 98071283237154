import { MutableRefObject, useEffect, useRef, useState } from "react";

// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.

// hook returns tuple(array) with type [any, boolean]
export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref: any = useRef<T | null>(null);
  const on = (): void => setValue(true);
  const off = (): void => setValue(false);
  useEffect(
    () => {
      const node: any = ref.current;

      const outsideOff = (e: any) => {
        if (node && !node.contains(e.target)) {
          off();
        }
      };

      if (node) {
        node.addEventListener("mouseenter", on);
        node.addEventListener("mouseleave", off);
        node.addEventListener("mousemove", on);
        node.addEventListener("focus", on);
        node.addEventListener("blur", off);
        document.addEventListener("mousemove", outsideOff);
        return () => {
          node.removeEventListener("mouseenter", on);
          node.removeEventListener("mouseleave", off);
          node.removeEventListener("mousemove", on);
          node.removeEventListener("focus", on);
          node.removeEventListener("blur", off);
          document.removeEventListener("mousemove", outsideOff);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}
