import { downloadAsFile } from "shared/lib/download-as-file";

type Params = {
  data: unknown;
  filename: string;
};

export function useDownloadAsFile({ data, filename }: Params) {
  const download = () => {
    try {
      downloadAsFile(data, filename)();
    } catch (error) {
      console.error(error);
    }
  };

  return download;
}
