import React, { useRef, VFC } from "react";

import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";

type Props = Omit<ModalProps, "children"> & {
  onConfirm: () => void;
  title: string;
  description: string;
  cancelText?: string;
  confirmText?: string;
};

const ModalConfirmation: VFC<Props> = ({
  onConfirm,
  title,
  description,
  cancelText = "No",
  confirmText = "Yes",
  ...modalProps
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null);
  const confirmRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      initialFocusRef={cancelRef}
      finalFocusRef={confirmRef}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          <Button ref={cancelRef} onClick={modalProps.onClose}>
            {cancelText}
          </Button>
          <Button ref={confirmRef} colorScheme="red" ml={3} onClick={onConfirm}>
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirmation;
