import { useMemo, useRef, useState } from "react";

import { t } from "@lingui/macro";

import { Box, Input } from "@chakra-ui/react";

type Props = {
  teamData: string[];
  onSelect: (value: string) => void;
  onChange: (value: string) => void;
  value: string;
};

export const Autocomplete: React.FC<Props> = ({
  teamData,
  onSelect,
  onChange,
  value,
}) => {
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(
    () =>
      teamData.filter((email) =>
        email?.toLowerCase().includes(value.toLowerCase())
      ),
    [value, teamData]
  );

  const handleInputChange = (value: string) => {
    const trimedValue = value.trim();
    onChange(trimedValue);
    if (!value) {
      setShowAutocomplete(true);
    }
  };

  const handleSelectOption = (option: string) => {
    const trimedValue = option.trim();
    onSelect(trimedValue);
    onChange(trimedValue);
    setShowAutocomplete(false);
  };

  return (
    <Box
      position="relative"
      onBlur={(e) => {
        if (!inputRef.current?.contains(e.relatedTarget as Node)) {
          setShowAutocomplete(false);
        }
      }}
    >
      <Input
        ref={inputRef}
        value={value}
        onChange={(e) => {
          handleInputChange(e.target.value);
        }}
        onFocus={() => {
          if (
            !value ||
            teamData.some((email) =>
              email.toLowerCase().includes(value.toLowerCase())
            )
          ) {
            setShowAutocomplete(true);
          }
        }}
        placeholder={t`Type email...`}
        autoComplete="off"
        id="email"
        mr={4}
        minW={"280"}
        fontSize="md"
        bgColor="gray.100"
        borderColor="gray.400"
        borderWidth="1px"
        pl="0.5rem"
      />
      {showAutocomplete && filteredOptions.length > 0 && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          right={0}
          zIndex={10}
          boxShadow="md"
          mt="2"
          bg="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
        >
          {filteredOptions.map((option) => (
            <Box
              key={option}
              onMouseDown={(e) => {
                e.preventDefault();
                handleSelectOption(option);
              }}
              p="2"
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
            >
              {option}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
