import { Icon, IconProps } from "@chakra-ui/react";

export const Buildings = (props: IconProps) => {
  return (
    <Icon
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M165.693 0V245.581H45.1426V512H257.15H267.569H466.858V0H165.693ZM246.731 491.162H65.9808V266.419H246.731V491.162V491.162ZM446.019 491.162H267.569V245.581H186.533V20.8382H446.021V491.162H446.019Z"
        fill="black"
      />
      <path d="M412.28 245.584H301.143V266.422H412.28V245.584Z" fill="black" />
      <path d="M412.28 306.363H301.143V327.201H412.28V306.363Z" fill="black" />
      <path d="M412.28 367.141H301.143V387.979H412.28V367.141Z" fill="black" />
      <path
        d="M211.924 306.363H100.787V327.201H211.924V306.363Z"
        fill="black"
      />
      <path
        d="M211.924 367.141H100.787V387.979H211.924V367.141Z"
        fill="black"
      />
      <path
        d="M211.924 427.916H100.787V448.754H211.924V427.916Z"
        fill="black"
      />
      <path
        d="M412.278 184.807H222.998V205.645H412.278V184.807Z"
        fill="black"
      />
      <path
        d="M412.278 124.029H222.998V144.867H412.278V124.029Z"
        fill="black"
      />
      <path d="M412.278 63.25H222.998V84.0882H412.278V63.25Z" fill="black" />
    </Icon>
  );
};
