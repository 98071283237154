import { Icon, IconProps } from "@chakra-ui/react";

export const Tractor = (props: IconProps) => {
  return (
    <Icon
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M95.7902 277.822C42.9115 277.822 0 321.153 0 374.452C0 400.471 9.96721 424.812 28.118 442.963C46.1639 461.114 70.1902 471.081 95.7902 471.081C149.403 471.081 191.475 428.589 191.475 374.452C191.475 348.642 181.613 324.406 163.777 306.255C145.731 287.894 121.6 277.822 95.7902 277.822ZM95.7902 450.098C54.5574 450.098 20.9836 416.209 20.9836 374.452C20.9836 332.694 54.5574 298.806 95.7902 298.806C136.918 298.806 170.492 332.694 170.597 374.452C170.597 416.839 137.757 450.098 95.7902 450.098Z"
        fill="black"
      />
      <path
        d="M493.325 361.443C481.154 349.168 464.997 342.453 447.79 342.453C412.223 342.453 384.42 370.886 384.42 407.188C384.42 442.965 412.223 471.083 447.79 471.083C483.777 471.083 512 442.965 512 407.293C512 389.876 505.39 373.614 493.325 361.443ZM447.79 450.099C423.659 450.099 405.404 431.633 405.404 407.188C405.404 382.637 424.079 363.437 447.79 363.437C472.026 363.437 491.017 382.637 491.017 407.188C491.017 431.214 472.026 450.099 447.79 450.099Z"
        fill="black"
      />
      <path
        d="M474.964 331.543L493.219 350.008C497.311 354.1 503.921 354.205 508.013 350.113C512.105 345.917 512.21 339.307 508.118 335.215L486.819 313.707C484.826 311.713 482.203 310.559 479.37 310.559H468.564C468.564 310.349 468.564 310.14 468.564 309.825V234.913C468.564 229.143 463.842 224.422 458.072 224.422H415.685C415.685 224.212 415.685 224.002 415.685 223.687V62.6379C415.685 56.8674 410.964 52.1461 405.193 52.1461H373.613C367.842 52.1461 363.121 56.8674 363.121 62.6379C363.121 68.4084 367.842 73.1297 373.613 73.1297H394.701V223.687C394.701 223.897 394.701 224.107 394.701 224.422H285.167L233.652 48.474C232.393 43.9625 228.196 40.9199 223.58 40.9199H64.2096C58.8587 40.9199 54.3473 45.0117 53.8227 50.3625L32.8391 251.805L3.77677 281.917C-0.210111 286.113 -0.105193 292.723 4.09153 296.71C8.28825 300.697 14.8981 300.592 18.885 296.395L47.3178 266.913H163.987L225.154 401.208C226.833 404.985 230.61 407.399 234.701 407.399H351.475C355.252 407.399 358.715 405.405 360.603 402.048L400.262 331.543H474.964ZM160.314 62.6379V245.93H54.5571L73.6522 61.9035H160.314C160.314 62.1134 160.314 62.3231 160.314 62.6379ZM181.298 254.113V62.533C181.298 62.3232 181.298 62.0084 181.298 61.7986H215.816L266.806 236.382V288.946H197.141L181.298 254.113ZM384.944 315.91L345.285 386.415H241.416L206.688 310.035H277.298C283.069 310.035 287.79 305.313 287.79 299.543V245.405H447.58V267.543H405.193C399.423 267.543 394.701 272.264 394.701 278.035C394.701 283.805 399.423 288.526 405.193 288.526H447.58V309.93C447.58 310.14 447.58 310.454 447.58 310.664H394.072V310.559C390.295 310.559 386.833 312.553 384.944 315.91Z"
        fill="black"
      />
    </Icon>
  );
};
