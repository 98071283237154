import React from "react";

import { Tooltip } from "@chakra-ui/react";

interface TooltipWrapperProps {
  label?: string;
  children: React.ReactNode;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({ label, children }) => {
  if (!label) {
    return <>{children}</>;
  }

  return (
    <Tooltip label={label} hasArrow>
      {children}
    </Tooltip>
  );
};

export default TooltipWrapper;
