import { Icon, IconProps } from "@chakra-ui/react";

export const UavHouse = (props: IconProps) => {
  return (
    <Icon {...props} id="Layer_1" x="0px" y="0px" viewBox="0 0 122.88 114.45">
      <g>
        <path
          style={{ fillRule: "evenodd", clipRule: "evenodd" }}
          d="M110.8,107.88v4.48c0,1.16-0.94,2.09-2.09,2.09h-95.1c-1.16,0-2.09-0.94-2.09-2.09v-4.48V84.2v-25.3 c-2.34,0.9-4.53,0.92-6.35,0.3c-1.42-0.48-2.62-1.34-3.5-2.45c-0.88-1.11-1.44-2.46-1.61-3.95c-0.26-2.31,0.43-4.92,2.4-7.37l0,0 c0.1-0.12,0.21-0.24,0.34-0.34L59.85,0.55c0.74-0.68,1.88-0.75,2.7-0.11l57.19,44.46l0,0c0.09,0.07,0.17,0.14,0.25,0.23 c2.65,2.85,3.31,6.01,2.67,8.68c-0.32,1.32-0.95,2.51-1.82,3.48c-0.87,0.98-1.98,1.74-3.24,2.19c-2,0.72-4.38,0.7-6.79-0.44V84.2 V107.88L110.8,107.88z M5.57,48.23c-1.11,1.45-1.5,2.9-1.37,4.12c0.08,0.7,0.33,1.32,0.73,1.82c0.39,0.49,0.92,0.86,1.56,1.08 c1.09,0.37,2.5,0.27,4.11-0.49l49.79-37.88c0.77-0.59,1.82-0.56,2.55,0.01l0,0l48.37,37.6c0.02,0.02,0.04,0.04,0.07,0.05 c1.77,1.28,3.47,1.48,4.79,1.01c0.61-0.22,1.14-0.58,1.55-1.05c0.42-0.47,0.72-1.03,0.87-1.66c0.33-1.38-0.07-3.08-1.55-4.74 L61.27,4.74L5.57,48.23L5.57,48.23z M29.6,75.49h26.7c1.04,0,1.89,0.83,1.89,1.85v20.89c0,1.02-0.85,1.85-1.89,1.85H29.6 c-1.04,0-1.89-0.83-1.89-1.85V77.34C27.72,76.32,28.56,75.49,29.6,75.49L29.6,75.49z M67.73,73.65H92.8c1.12,0,2.03,0.94,2.03,2.1 v34.52h11.78V56.57c0-0.14,0.01-0.27,0.04-0.4L61.64,21.18L15.63,56.19c0.05,0.17,0.07,0.35,0.07,0.54v53.53h50V75.74 C65.7,74.58,66.61,73.65,67.73,73.65L67.73,73.65z M90.77,77.84h-21v32.38h21V77.84L90.77,77.84z M54.41,89.63H44.5v6.75h9.91 V89.63L54.41,89.63z M40.8,89.63h-9.31v6.75h9.31V89.63L40.8,89.63z M31.49,85.93h9.31v-6.74h-9.31V85.93L31.49,85.93z M44.5,85.93 h9.91v-6.74H44.5V85.93L44.5,85.93z M60.88,36.33c3.55,0,6.43,2.88,6.43,6.43c0,3.55-2.88,6.43-6.43,6.43 c-3.55,0-6.43-2.88-6.43-6.43C54.45,39.2,57.33,36.33,60.88,36.33L60.88,36.33z"
        />
      </g>
    </Icon>
  );
};
