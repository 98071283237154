import { memo } from "react";

import { useMutation, useQueryClient } from "react-query";

import { yupResolver } from "@hookform/resolvers/yup";
import { t, Trans } from "@lingui/macro";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";

import { shareProject } from "shared/api/client";

import { Role, ShareProjectPayload } from "types";

import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Autocomplete } from "./autocomplete";

const schema = yup.object({
  email: yup.string().email(t`Email must be a valid email address`),
});

type Props = {
  teamMemberEmails: string[];
  projectId: string;
};

const ShareExternaly: React.FC<Props> = ({ projectId, teamMemberEmails }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const shareProjectMutation = useMutation(
    async (payload: ShareProjectPayload) => {
      return shareProject(payload);
    },
    {
      onSuccess: (_, payload) => {
        const email = payload.email;
        toast({
          title: t`Project shared`,
          description: t`Successfully shared with ${email}`,
          position: "bottom",
          status: "success",
          isClosable: true,
        });
        queryClient.invalidateQueries(["project", projectId]);
      },
      onError: (error: any, payload) => {
        console.log(error, payload, "error");
        const email = payload.email;
        const isNonRegisteredUser = error?.response?.data?.code === "NOT_FOUND";

        if (isNonRegisteredUser) {
          toast({
            title: t`Failed to share project`,
            description: t`User ${email} is not registered to Mapflow.ai, invite them to register first`,
            isClosable: true,
            duration: 10000,
            position: "bottom",
            status: "error",
          });
        } else {
          toast({
            title: t`Failed to share project`,
            description: t`Could not share with ${email}`,
            isClosable: true,
            duration: 10000,
            position: "bottom",
            status: "error",
          });
        }
      },
    }
  );

  const onSubmit = (data: any) => {
    if (!data.email.trim()) {
      return;
    }
    const payload = {
      ...data,
      role: Role.readOnly,
      projectId,
    };
    shareProjectMutation.mutate(payload);
    reset({
      email: "",
    });
  };

  return (
    <Stack p={4} mt={4} direction={"column"}>
      <Heading fontSize="24px" fontWeight="extrabold">
        <Trans>Share externally (view only)</Trans>
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir="row" align="center">
          <FormControl
            isInvalid={!!errors.email}
            mr={4}
            display="flex"
            alignItems="center"
            w={"fit-content"}
          >
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field }) => (
                <Autocomplete
                  teamData={teamMemberEmails}
                  value={field.value}
                  onSelect={(option: any) => field.onChange(option.email)}
                  onChange={field.onChange}
                />
              )}
            />
            <FormErrorMessage position={"absolute"} pt="0" top={8}>
              {errors.email?.type === "email" &&
                t`Email must be a valid email address`}
            </FormErrorMessage>
          </FormControl>

          <Button
            borderRadius="lg"
            colorScheme="blue"
            isLoading={shareProjectMutation.isLoading}
            type="submit"
          >
            <Trans>Share</Trans>
          </Button>
        </Flex>
      </form>
    </Stack>
  );
};

export const ShareUserExternaly = memo(ShareExternaly);
